<template>

  <drawer-form
          ref="drawerForm"
          title="编辑产品"
          :model="model"
          :rules="rules"
          :loading="loading"
          @handleSubmit="handleSubmit">

    <!--    <a-form-model-item label='图片' prop="logo">-->
    <!--      <multi-image-upload v-model="model.logo"></multi-image-upload>-->
    <!--    </a-form-model-item>-->

    <a-form-model-item label='产品名称' prop="name">
      <a-input v-model="model.name"/>
    </a-form-model-item>

    <a-form-model-item label='产品描述' prop="des">
      <a-input v-model="model.des"/>
    </a-form-model-item>

<!--    <a-form-model-item label='产品分类' prop="classifyId">-->
<!--      <a-select style="width: 100%" v-model="model.classifyId">-->
<!--        <a-select-option value="0">无</a-select-option>-->
<!--        <a-select-option v-for="classify in classifyList" :value="classify.id">{{classify.name}}</a-select-option>-->
<!--      </a-select>-->
<!--    </a-form-model-item>-->

<!--    <a-form-model-item label='分享方式' prop="shareMethod">-->
<!--      <a-select style="width: 100%" v-model="model.shareMethod">-->
<!--        <a-select-option value="0">共享式</a-select-option>-->
<!--        <a-select-option value="1">授权式</a-select-option>-->
<!--        <a-select-option value="2">抢占式</a-select-option>-->
<!--      </a-select>-->
<!--    </a-form-model-item>-->

    <a-form-model-item label='产品图片' prop="devicePicture">
      <image-upload v-model="model.devicePicture" hint="建议尺寸比例为1:1"></image-upload>
    </a-form-model-item>

  </drawer-form>

</template>

<script>

export default {

  data () {
    return {
      model: {
        name:"",
        des:"",
        classifyId:"",
        shareMethod:"1",
        productKey: null,
        devicePicture: null,
        wifiPictureOne: null,
        wifiPictureTwo: null
      },
      classifyList:[],
      loading: false,
      rules:{},
    }
  },
  methods: {
    hide () {
      this.$refs.drawerForm.hide()
    },
    show (model = {}) {
      this.model = JSON.parse(JSON.stringify(model))
      this.model.shareMethod+=""

      let paras = {
        pageNum: 1,
        pageSize: 2000,
      }
      this.$get('product_classify', paras).then((r) => {
        let data = r.data
        this.classifyList = data.rows
      })

      this.$refs.drawerForm.show()

    },
    handleSubmit () {
      this.loading = true
      this.$put('product', {
        ...this.model
      }).then((r) => {
        this.loading = false
        this.isShow = false
        this.hide()
        this.$emit('success')
      }).catch((err)=>{
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
