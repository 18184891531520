<template>
  <page-content>

    <common-table
      ref="table"
      path="product/page"
      :columns="columns"
      >

      <template slot="search">
<!--        <a-button ghost type="primary" @click="$router.push('/device/product/classify')" >产品分类管理</a-button>-->
      </template>

      <template slot="icon" slot-scope="{record}">
        <a-avatar shape="square" :size="40" :src="record.devicePicture">{{record.name}}</a-avatar>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-edit @click="edit(record)"></action-edit>
      </template>

    </common-table>

    <product-edit ref="productEdit" @success="getList"></product-edit>
  </page-content>
</template>

<script>
import TimeUtils from '@/utils/TimeUtils'
import ProductEdit from './ProductEdit'

export default {
  name: 'Product',
  components: {
    ProductEdit
  },
  data () {
    return {
    }
  },

  computed: {
    columns () {
      return [
        {
          title: '产品图片',
          dataIndex: 'productIcon',
          scopedSlots: {customRender: 'icon'}
        },
        {
          title: '产品名称',
          dataIndex: 'name'
        },
        // {
        //   title: '分享方式',
        //   dataIndex: 'shareMethod',
        //   customRender: (text, row, index) => {
        //     return ["共享式","授权式","抢占式"][parseInt(text)]
        //   }
        // },
        {
          title: '联网类型',
          dataIndex: 'netType'
        },
        {
          title: '创建时间',
          dataIndex: 'createdAt',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {

    edit (record) {
      this.$refs.productEdit.show(record)
    },
    view(record){
      this.$router.push("/device/product/"+record.productKey+"/model")
    },
    getList () {
      this.$refs.table.getData()
    }
  }
}
</script>

<style scoped>

</style>
